import { useState, useEffect } from "react";
import LoginForm from "../components/page-components/forms/LoginForm";
import AuthHeader from "../components/page-components/auth/AuthHeader";
// import GoogleButton from "../components/buttons/GoogleButton";
// import AuthDivider from "../components/page-components/auth/AuthDivider";
import ErrorMessage from "../components/error/ErrorMessage";
import Redirect from "../components/page-components/auth/Redirect";
import { fetchPage } from "../services/useFetch";
import { pageFilter } from "../services/cms/pages";
// import { useRouter } from "next/router";

// import { getSession } from "next-auth/client";

const Login = ({ pageData }) => {
  // const googleProvider = providers.google.id;
  const [error, setError] = useState();
  const [visible, setVisible] = useState(false);

  // const router = useRouter();

  // const positiveResponseGoogle = async (response) => {
  //   const data = {
  //     access_token: response.accessToken,
  //     id_token: response.idToken,
  //     email: response.profileObj.email,
  //     first_name: response?.profileObj?.givenName,
  //     last_name: response?.profileObj?.familyName,
  //   };

  //   const responseAPI = await fetchWrapper("/api/login/google", {
  //     method: "POST",
  //     data,
  //   });

  //   if (responseAPI.status === 200) {
  //     const data = await responseAPI.json();
  //     clientCookieSet(data.access_token, data.refresh_token, true);

  //   } else {
  //     try {
  //       const msg = await response?.json();
  //       setError(msg?.error);
  //       setVisible(true);
  //     } catch (err) {
  //       setError("Oops, something went wrong.");
  //       setVisible(true);
  //     }
  //   }
  // };

  // };

  useEffect(() => {});

  return (
    <div className="auth container">
      <div className="wrapper-form">
        <AuthHeader pageData={pageData ? pageData[0] : null} />
        {/* <GoogleLogin
          clientId="584643384358-ck6a8dph9o5dnf7fm8jjtere9s02s4c7.apps.googleusercontent.com"
          render={(renderProps) => ( */}
        {/* <GoogleButton
          providerId={googleProvider}
          content="Login with Google"
          // renderProps={renderProps}
        /> */}
        {/* )}
          buttonText="Login"
          onSuccess={positiveResponseGoogle}
          cookiePolicy={"single_host_origin"}
        /> */}
        {/* <AuthDivider /> */}
        <ErrorMessage
          content={error}
          visible={visible}
          setVisible={setVisible}
        />
        <LoginForm
          setError={setError}
          setVisible={setVisible}
          // providers={providers}
        />

        <Redirect
          content="Don't have an account?"
          link="/signup"
          linkContent="Sign Up"
        />
      </div>
    </div>
  );
};

// Login.getInitialProps = async (context) => {
//   const { req, res, next } = context;
//   // const session = await getSession(req);

//   // if (session && res && session.accessToken) {
//   //   res.writeHead(302, {
//   //     Location: "/dashboard",
//   //   });
//   //   res.end();
//   //   return;
//   // }
//   return {
//     // session: undefined,
//     providers: await providers(context),
//     // csrfToken: await csrfToken(context),
//   };
// };

export async function getServerSideProps({ req }) {
  const { cookies } = req;
  const userStatus = await fetch(
    `${process.env.NEXT_PUBLIC_BASE_CMS}/api/user/dashboard`,
    {
      credentials: "include",
      headers: {
        Cookie: `boho_session=${cookies.boho_session}`,
      },
    },
  );

  if (userStatus.status === 200) {
    return {
      redirect: {
        permanent: false,
        destination: "/dashboard",
      },
      props: {},
    };
  } 

  const pageStatus = await fetchPage("pages.AuthPage");

  let pageData;
  if (pageStatus.status === 200) {
    const pageTemp = await pageStatus.json();
    pageData = await pageFilter(pageTemp, "login");
  } 

  return {
    props: { pageData: pageData || null },
  };
}

export default Login;
