import { useState, useEffect } from "react";
import AuthInputs from "./components/AuthInputs";
import { useRouter } from "next/router";
import BrownSubmit from "../../buttons/BrownSubmit";
import { login } from "../../../services/cms/auth";
import { loginValidation } from "../../../services/validation/authValidation";
import Link from "next/link";
import MailIcon from "../../icons/auth_inputs/mail.svg";
import LockIcon from "../../icons/auth_inputs/lock.svg";


const LoginForm = ({ setError, setVisible }) => {
  // const providerId = providers.credentials.id;

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const router = useRouter();

  const { error } = router.query;
  const { asPath } = router;

  const onSubmitForm = async (event) => {
    event.preventDefault();

    const fullData = {
      email: email?.trim().toLowerCase(),
      password,
    };

    const isValid = loginValidation(fullData);

    if (isValid.error) {
      setError(isValid.error);
      setVisible(true);
    } else if (isValid.success) {
      const response = await login(fullData);

      if (response?.status === 200) {
        router.push("/dashboard");
      } else {
        try {
          const errorMessage = await response.json();
          setError(errorMessage.status_message || errorMessage.detail);
          // WARNING: ".detail" is an error message that shows up if "Content-Type": "application/json" is in the request header
          setVisible(true);
        } catch (err) {
          console.log(err);
          setError(
            "Oops, something went wrong, and we're not sure what. Please reach out to info@thebohomarket.com",
          );
          setVisible(true);
        }
      }
    }
  };

  useEffect(() => {
    // if (error === "alert is not defined") {
    //   setError(
    //     "You need to reset your password. Please check your email for a password reset link.",
    //   );
    //   setVisible(true);
    // } else if (error?.length > 0) {
    //   setError("Oops, something went wrong.");
    //   setVisible(true);
    // } else if (asPath.endsWith("?")) {
    //   setError(
    //     "Oops, something went wrong. Sometimes it helps to close the window and open it again.",
    //   );
    //   setVisible(true);
    // }
  }, [error, asPath]);

  return (
    <form onSubmit={(e) => onSubmitForm(e)}>
      {/* <input name="csrfToken" type="hidden" defaultValue={csrfToken} /> */}
      <AuthInputs
        label="Email"
        value={email}
        setValue={setEmail}
        type="text"
        imageAlt="mail icon"
        imageURL={MailIcon}
        htmlFor="email"
        placeholder="Email"
        pattern={new RegExp(/^\S+@\S+$/i)}
      />

      <AuthInputs
        label="Password"
        value={password}
        setValue={setPassword}
        type="password"
        imageAlt="lock icon"
        imageURL={LockIcon}
        htmlFor="password"
        placeholder="Password"
        pattern={new RegExp(/^.*/i)}
      />
      <div className="forgot__password">
        <Link href="/forgot_password">
          <a>Forgot Password?</a>
        </Link>
      </div>
      <div style={{textAlign: "center"}}>
      <BrownSubmit
        paddingUpDown={20}
        paddingLeftRight={216}
        type="submit"
        content="Login"
        onSubmit={onSubmitForm}
      />
      </div>
    </form>
    /*
      {/* {Object.values(providers).map((provider) => {
        return (
          <button onClick={() => signIn(provider.id)}>
            Sign in with {provider.name}
          </button>
        );
      })}
      <button type="submit">Use your email</button> */
    /* <BrownSubmit
        paddingUpDown={20}
        paddingLeftRight={211}
        type="submit"
        content="Login"
        onSubmit={signIn({ providerId })}
      /> */
    /* <button
        onClick={() =>
          signIn(providerId, {
            email,
            password,
            callbackUrl: "http://localhost:3000/dashboard",
          })
        }
        type="submit"
        className="button__brownSubmit button"
        style={{ padding: "20px 211px" }}
        value="Login"
      >
        Login
      </button> */

    // </form>
  );
};

export default LoginForm;
