export const signupValidation = (data) => {
  let result;
  if (!data) {
    result = { error: "Oops, nothing was filled out." };
  } else if (!data.first_name) {
    result = { error: "Oops, please fill out your first name." };
  } else if (!data.last_name) {
    result = { error: "Oops, please fill out your last name." };
  } else if (!data.phone) {
    result = { error: "Oops, please fill out your phone number." };
  } else if (!data.email) {
    result = { error: "Oops, please fill out your email." };
  } else if (!data.password1) {
    result = { error: "Oops, please select a password." };
  } else if (!data.password2) {
    result = { error: "Oops, please confirm your password." };
  } else if (data.terms === false) {
    result = {
      error:
        "Oops, you need to agree to the Booth Rental and Display Agreement before you create an account.",
    };
  } else if (data.privacy === false) {
    result = {
      error:
        "Oops, you need to agree to the Privacy Policy and Booth Rental and Display Agreement before you create an account.",
    };
  } else if (data.password1 !== data.password2) {
    result = { error: "Oops, the passwords don't match" };
  } else {
    result = { success: "Passed validation" };
  }

  return result;
};

export const loginValidation = (data) => {
  let result;
  if (!data) {
    result = { error: "Oops, nothing was filled out." };
  } else if (!data.email) {
    result = { error: "Oops, please fill out your email." };
  } else if (!data.password) {
    result = { error: "Oops, please fill in your password." };
  } else {
    result = { success: "Passed validation" };
  }

  return result;
};

export const migrateValidation = (data) => {
  let result;
  if (!data) {
    result = { error: "Oops, nothing was filled out." };
  } else if (!data.password1) {
    result = { error: "Oops, please select a password." };
  } else if (!data.password2) {
    result = { error: "Oops, please confirm your password." };
  } else if (data.password1 !== data.password2) {
    result = { error: "Oops, the passwords don't match" };
  } else {
    result = { success: "Passed validation" };
  }

  return result;
};

export const invalidPasswordMessage = (array) => {
  let message = [];
  for (const m of array) {
    if (m === "min") {
      // message.push(
      //   "Please include one of the following 8 symbols in your password: !, @, #, $, %, ^, &, *",
      // );
      // message.concat(
      //   "",
      //   "Please include one of the following 8 symbols in your password: !, @, #, $, %, ^, &,*",
      // );

      message.push("Your password must be at least 8 characters long.");
    } else if (m === "max") {
      message.push(
        "We're sorry, but your password is too long, please shorten it to 100 characters or less.",
      );
    } else if (m === "lowercase") {
      message.push("Your password must include at least one lowercase letter.");
    } else if (m === "uppercase") {
      message.push("Your password must include at least one uppercase letter.");
    } else if (m === "digits") {
      message.push("Your password must include at least one number.");
    } else if (m === "spaces") {
      message.push("Your password cannot contain spaces.");
    } else if (m === "symbols") {
      message.push(
        "Please include one of the following 8 symbols in your password: !, @, #, $, %, ^, &, *",
      );
    }
  }
  return message;
};

export const invalidEmaillMessage = (data) => {
  let result;
  if (!data.email) {
    result = { error: "Oops, please fill out your email." };
  } else {
    result = { success: "You included your email." };
  }

  return result;
};
